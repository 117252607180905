/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.hidden {
    display: none;
}

.block {
    display:block;
}

.slideWidth {
    width:250px !important;
}

.spindleWidth {
    width:150px !important;
}

.compact {
    margin-top: 1.5em !important;
}

.tabAuxWidth {
    max-width: 10rem !important;
}
